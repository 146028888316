import React, { FC, useCallback, useMemo, useState } from "react";
import {
  Button,
  Card,
  Box,
  CardContent,
  Modal,
  Stack,
  TextField,
  Typography,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import {
  Delete,
  SellSharp,
  LocalOffer,
  Cancel,
  Approval,
  Send,
  Add,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TransactionResponse } from "@ethersproject/providers";
import { InjectedModalProps } from "../../../components/Modal";
import { useApproveNftCallback } from "../../../hooks/useApproveNftCallback";
import {
  Currency,
  GhosperNftV1,
  GhosperNftV2,
  MarketPlace,
} from "../../../constants";
import { BigNumber } from "ethers";
import {
  ApprovalState,
  useApproveCallback,
} from "../../../hooks/useApproveCallback";
import { useGhosperMarketplaceContract } from "../../../hooks/useContract";
import { formatEther, parseEther } from "ethers/lib/utils";
import useToast from "../../../hooks/useToast";
import { useCall } from "../../../hooks/useCall";
import api from "../../../utils/api";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface AcceptOfferModalProps extends InjectedModalProps {
  nftData: any;
  offerIndex: number;
  fetchData: () => void;
}

const AcceptOfferModal: FC<AcceptOfferModalProps> = ({
  onDismiss,
  nftData,
  offerIndex,
  fetchData,
}) => {
  const { metaData, orderData, offerData, owner, v } = nftData;
  const [approval, approveCallback] = useApproveNftCallback(
    v === 1 ? GhosperNftV1 : GhosperNftV2,
    BigNumber.from(metaData.tokenId),
    MarketPlace
  );

  const ghosperMarketplaceContract = useGhosperMarketplaceContract();

  const ghosperMarketContract = useGhosperMarketplaceContract();
  const addToast = useToast();
  const { call } = useCall();

  const [pendingAccept, setPendingAccept] = useState(false);

  const handleAccept = () => {
    call(ghosperMarketContract!, "acceptOffer", [
      v == 1 ? GhosperNftV1 : GhosperNftV2,
      BigNumber.from(metaData.tokenId),
      offerIndex,
      offerData[3][offerIndex],
      offerData[4][offerIndex],
    ])
      .then((response: TransactionResponse) => {
        setPendingAccept(true);
        response
          .wait()
          .then(() => {
            addToast("You accepted offer!", "success");
            api.post("/history", {
              v,
              tokenId: metaData.tokenId,
              currency: offerData[3][offerIndex],
              price: formatEther(offerData[4][offerIndex]),
            });
          })
          .catch(() => {
            addToast("Transaction failed!", "error");
          })
          .finally(() => {
            setPendingAccept(false);
            if (fetchData) fetchData();
            if (onDismiss) onDismiss();
          });
      })
      .catch((error: any) => {
        console.log(error);
        if (error?.code !== 4001) {
          addToast(error.message, "error");
        }
      });
  };

  return (
    <Modal open={true}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Accept Offer
        </Typography>

        <Typography my={2}>
          To accept offer, you need to approve the Transaction first.
        </Typography>
        <Stack spacing={2} direction="row">
          {approval !== ApprovalState.APPROVED && owner !== MarketPlace ? (
            <LoadingButton
              loading={approval == ApprovalState.PENDING}
              variant="contained"
              startIcon={<Approval />}
              fullWidth
              loadingPosition="start"
              type="button"
              disabled={approval == ApprovalState.PENDING}
              onClick={approveCallback}
            >
              Approve
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={pendingAccept}
              variant="contained"
              startIcon={<LocalOffer />}
              fullWidth
              loadingPosition="start"
              type="button"
              disabled={pendingAccept}
              onClick={handleAccept}
            >
              Accept
            </LoadingButton>
          )}

          <Button
            type="button"
            variant="outlined"
            startIcon={<Cancel />}
            onClick={onDismiss}
            fullWidth
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default AcceptOfferModal;
