import { useDispatch } from "react-redux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";

// user defined
import authReducer from "./user/reducer";
import filterReducer from "./filter/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  filter: filterReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
});

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
