import BNBToken from "../assets/img/tokens/BNB.png";
import BUSDToken from "../assets/img/tokens/BUSD.png";
import GHSPToken from "../assets/img/tokens/GHSP.png";
export { default as BSCImg } from "../assets/img/bsc.svg";

export const TokenImg = {
  BNB: BNBToken,
  BUSD: BUSDToken,
  GHSP: GHSPToken,
};

export enum ChainId {
  MAINNET = 56,
  TESTNET = 97,
}

export const CHAIN_ID = ChainId.MAINNET;
  // process.env.APP_ENV === "production" ? ChainId.MAINNET : ChainId.TESTNET;

console.log("app env here: ", process.env.APP_ENV);

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: "https://bscscan.com",
  [ChainId.TESTNET]: "https://testnet.bscscan.com",
};

export const Currency = {
  BNB: {
    symbol: "BNB",
    address: "0x0000000000000000000000000000000000000000",
  },
  BUSD: {
    symbol: "BUSD",
    address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
  },
  GHSP: {
    symbol: "GHSP",
    address: "0x4a0Cc0876EC16428A4Fa4a7C4c300dE2DB73b75b",
  },
};

// ================ Mainnet ================

export const GhosperNftV1 = "0x1A76A513aaA1EC2A28feb05be181C13301d97aBa";
export const GhosperNftV2 = "0x06A9C249DB464a4441BcB5948fFE5b4B5B2f66e8";
export const MarketPlace = "0x4869C09660866d5ec2FdDA48E13aD78576a98ec0";

// ================ Mainnet ================


// ================ Testnet ================
// export const GhosperNftV1 = "0x1A76A513aaA1EC2A28feb05be181C13301d97aBa";
// export const GhosperNftV2 = "0x43c01D043BB7A4A1FA0e75e327F44942c21d09FC";
// export const MarketPlace = "0x43c01D043BB7A4A1FA0e75e327F44942c21d09FC";

// ================ Testnet ================

export const itemsPerPage = 15;
