import { useEffect, useMemo, useState } from "react";
import { useERC20Contract } from "./useContract";
import get from "lodash/get";
import { useCall } from "./useCall";
import { BigNumber } from "ethers";

const useTokenAllowance = (
  token?: string,
  owner?: string,
  spender?: string
): BigNumber | undefined => {
  const contract = useERC20Contract(token);

  const [allowance, setAllowance] = useState(undefined as any);

  useEffect(() => {
    if (contract && owner && spender)
      contract.allowance(owner, spender).then((res) => setAllowance(res));
  }, [contract, owner, spender, allowance]);

  return useMemo(
    () => (token && allowance ? BigNumber.from(allowance) : undefined),
    [token, allowance]
  );
};

export default useTokenAllowance;
