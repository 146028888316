import { useWeb3React } from "@web3-react/core";
import { BigNumber, constants } from "ethers";
import { useCallback, useMemo, useState } from "react";
import { TransactionResponse } from "@ethersproject/providers";
// user defined
import useTokenAllowance from "./useTokenAllowance";
import { useCall } from "./useCall";
import useToast from "./useToast";
import { useERC20Contract } from "./useContract";

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

export function useApproveCallback(
  token?: string,
  amountToApprove?: BigNumber,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  const { account } = useWeb3React();
  const { call } = useCall();
  const [pending, setPending] = useState(false);
  const addToast = useToast();

  const tokenAddress = token == constants.AddressZero ? undefined : token;

  const currentAllowance = useTokenAllowance(
    tokenAddress,
    account ?? undefined,
    spender
  );

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (token === constants.AddressZero) return ApprovalState.APPROVED;
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(amountToApprove)
      ? pending
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [token, amountToApprove, currentAllowance, spender, pending]);

  const tokenContract = useERC20Contract(tokenAddress);

  const approve = useCallback(async (): Promise<void> => {
    // if (approvalState !== ApprovalState.NOT_APPROVED) {
    //   addToast("Approve was called unnecessarily", "error");
    //   console.error("approve was called unnecessarily");
    //   return;
    // }
    if (!token) {
      addToast("No Currency", "error");

      console.error("no token");
      return;
    }

    if (!tokenContract) {
      addToast(`Cannot find contract`, "error");

      console.error("tokenContract is null");
      return;
    }

    if (!amountToApprove) {
      addToast("Missing amount to approve", "error");

      console.error("missing amount to approve");
      return;
    }

    if (!spender) {
      addToast("No spender", "error");

      console.error("no spender");
      return;
    }

    // eslint-disable-next-line consistent-return
    return call(tokenContract, "approve", [spender, amountToApprove])
      .then((response: TransactionResponse) => {
        setPending(true);
        response.wait().then(() => {
          addToast("Approved!", "success");
          setPending(false);
        });
      })
      .catch((error: any) => {
        console.error("Failed to approve token", error);
        if (error?.code !== 4001) {
          addToast(error.message, "error");
        }
        throw error;
      });
  }, [approvalState, token, tokenContract, amountToApprove, spender, call]);

  return [approvalState, approve];
}
