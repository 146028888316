import sample from "lodash/sample";

// Array of available nodes to connect to
const nodes = {
  mainnet: [
    "https://bsc-dataseed1.binance.org/",
    "https://bsc-dataseed2.binance.org/",
    "https://bsc-dataseed3.binance.org/"
  ],
  devnet: [
    "https://data-seed-prebsc-1-s1.binance.org:8545/",
    "https://data-seed-prebsc-2-s1.binance.org:8545/",
    "http://data-seed-prebsc-1-s2.binance.org:8545/"
  ],
};

const getNodeUrl = () => {
  // if(process.env.APP_ENV === "production")
    return nodes.mainnet;
  // return nodes.devnet;
};

export default getNodeUrl;
