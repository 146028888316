import React, { FC, useState } from "react";
import {
  Button,
  Box,
  Modal,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { SellSharp, Cancel, Approval } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TransactionResponse } from "@ethersproject/providers";
import { InjectedModalProps } from "../../../components/Modal";
import { useApproveNftCallback } from "../../../hooks/useApproveNftCallback";
import {
  Currency,
  GhosperNftV1,
  GhosperNftV2,
  MarketPlace,
} from "../../../constants";
import { BigNumber } from "ethers";
import { ApprovalState } from "../../../hooks/useApproveCallback";
import { useGhosperMarketplaceContract } from "../../../hooks/useContract";
import { parseEther } from "ethers/lib/utils";
import useToast from "../../../hooks/useToast";
import { useCall } from "../../../hooks/useCall";
import { useSelector } from "react-redux";
import { AppState } from "../../../state";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface SellModalProps extends InjectedModalProps {
  nftData: any;
  fetchData: () => void;
}

const SellModal: FC<SellModalProps> = ({ onDismiss, nftData, fetchData }) => {
  const user = useSelector((state: AppState) => state.auth.user);
  const { metaData, v } = nftData;
  const [approval, approveCallback] = useApproveNftCallback(
    v === 1 ? GhosperNftV1 : GhosperNftV2,
    BigNumber.from(metaData.tokenId),
    MarketPlace
  );

  const ghosperMarketContract = useGhosperMarketplaceContract();
  const addToast = useToast();
  const { call } = useCall();

  const [sellPending, setSellPending] = useState(false);
  const formik = useFormik({
    initialValues: {
      currency: "",
      price: "",
    },
    validationSchema: Yup.object().shape({
      currency: Yup.string().required("select currency"),
      price: Yup.number()
        .min(0, "must greater than zero")
        .required("input tokenId"),
    }),
    onSubmit: async (values) => {
      let currency: string;
      if (values.currency === Currency.BNB.symbol)
        currency = Currency.BNB.address;
      else if (values.currency === Currency.BUSD.symbol)
        currency = Currency.BUSD.address;
      else currency = Currency.GHSP.address;

      call(ghosperMarketContract!, "createOrder", [
        v === 1 ? GhosperNftV1 : GhosperNftV2,
        BigNumber.from(metaData.tokenId),
        currency,
        parseEther(values.price.toString()),
      ])
        .then((response: TransactionResponse) => {
          setSellPending(true);
          response
            .wait()
            .then(() => {
              addToast("Succesfully put for sale!", "success");
            })
            .catch(() => {
              addToast("Transaction failed!", "error");
            })
            .finally(() => {
              setSellPending(false);
              if (fetchData) fetchData();
              if (onDismiss) onDismiss();
            });
        })
        .catch((error: any) => {
          console.log(error);
          if (error?.code !== 4001) {
            addToast(error.message, "error");
          }
        });
    },
  });

  return (
    <Modal open={true}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Sell Your NFT
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel>Currency</InputLabel>
            <Select
              value={formik.values.currency}
              label="Currency"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="currency"
            >
              <MenuItem value={Currency.BNB.symbol}>BNB</MenuItem>
              <MenuItem value={Currency.BUSD.symbol}>BUSD</MenuItem>
              {user.isAdmin && (
                <MenuItem value={Currency.GHSP.symbol}>GHSP</MenuItem>
              )}
            </Select>
            {Boolean(formik.touched.currency && formik.errors.currency) && (
              <FormHelperText>
                {formik.touched.currency && formik.errors.currency}
              </FormHelperText>
            )}
          </FormControl>

          <Box my={2}>
            <TextField
              type={"number"}
              value={formik.values.price}
              helperText={formik.touched.price && formik.errors.price}
              label="Price"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="price"
            />
          </Box>
          <Stack spacing={2} direction="row">
            {approval !== ApprovalState.APPROVED ? (
              <LoadingButton
                loading={approval === ApprovalState.PENDING}
                variant="contained"
                startIcon={<Approval />}
                fullWidth
                loadingPosition="start"
                type="button"
                disabled={approval === ApprovalState.PENDING}
                onClick={approveCallback}
              >
                Approve
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={sellPending}
                variant="contained"
                startIcon={<SellSharp />}
                fullWidth
                loadingPosition="start"
                type="submit"
                disabled={sellPending}
              >
                Sell
              </LoadingButton>
            )}

            <Button
              type="button"
              variant="outlined"
              startIcon={<Cancel />}
              onClick={onDismiss}
              fullWidth
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};
export default SellModal;
