import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { Web3ReactProvider } from "@web3-react/core";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
// user defined
import Views from "./views";
import store from "./state";
import { getLibrary } from "./utils/web3React";
import { ModalProvider } from "./components/Modal";

function App() {
  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <SnackbarProvider maxSnack={5} autoHideDuration={3000}>
          <Router>
            <Routes>
              <Route path="*" element={<Views />} />
            </Routes>
          </Router>
        </SnackbarProvider>
      </Web3ReactProvider>
    </Provider>
  );
}

export default App;
