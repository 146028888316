import { useWeb3React } from "@web3-react/core";
import { Web3ReactContextInterface } from "@web3-react/core/dist/types";
import { Web3Provider } from "@ethersproject/providers";

import { CHAIN_ID } from "../constants";
const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const { library, chainId, ...web3React } = useWeb3React();

  return {
    library: library,
    chainId: chainId ?? CHAIN_ID,
    ...web3React,
  };
};

export default useActiveWeb3React;
