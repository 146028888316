import React, { FC, useState } from "react";
import { Button, Box, Modal, Stack, Typography } from "@mui/material";
import { LocalOffer, Cancel, Approval } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { TransactionResponse } from "@ethersproject/providers";
import { InjectedModalProps } from "../../../components/Modal";
import {
  Currency,
  GhosperNftV1,
  GhosperNftV2,
  MarketPlace,
} from "../../../constants";
import { BigNumber } from "ethers";
import {
  ApprovalState,
  useApproveCallback,
} from "../../../hooks/useApproveCallback";
import { useGhosperMarketplaceContract } from "../../../hooks/useContract";
import useToast from "../../../hooks/useToast";
import { useCall } from "../../../hooks/useCall";
import api from "../../../utils/api";
import { formatEther } from "ethers/lib/utils";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface BuyModalProps extends InjectedModalProps {
  nftData: any;
  fetchData: () => void;
}

const BuyModal: FC<BuyModalProps> = ({ onDismiss, nftData, fetchData }) => {
  const { metaData, orderData, v } = nftData;
  const [approval, approveCallback] = useApproveCallback(
    orderData[2],
    orderData[3],
    MarketPlace
  );

  const ghosperMarketContract = useGhosperMarketplaceContract();
  const addToast = useToast();
  const { call } = useCall();

  const [pendingBuy, setPendingBuy] = useState(false);

  const handleBuy = () => {
    call(ghosperMarketContract!, "buyOrder", [
      v === 1 ? GhosperNftV1 : GhosperNftV2,
      BigNumber.from(metaData.tokenId),
    ])
      .then((response: TransactionResponse) => {
        setPendingBuy(true);
        response
          .wait()
          .then(() => {
            addToast("You bought it!", "success");
          })
          .catch(() => {
            addToast("Transaction failed!", "error");
            api.post("/history", {
              v,
              tokenId: metaData.tokenId,
              currency: orderData[2],
              price: formatEther(orderData[3]),
            });
          })
          .finally(() => {
            setPendingBuy(false);
            if (fetchData) fetchData();
            if (onDismiss) onDismiss();
          });
      })
      .catch((error: any) => {
        console.log(error);
        if (error?.code !== 4001) {
          addToast(error.message, "error");
        }
      });
  };
  return (
    <Modal open={true}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Buy NFT
        </Typography>

        <Typography my={2}>
          To buy NFT's in (
          {orderData[2] === Currency.BNB.address
            ? "BNB"
            : orderData[2] === Currency.BUSD.address
            ? "BUSD"
            : "GHSP"}
          ), you will need to approve this currency.
          <br />
          You can buy an NFT afterwards.
        </Typography>
        <Stack spacing={2} direction="row">
          {approval !== ApprovalState.APPROVED ? (
            <LoadingButton
              loading={approval === ApprovalState.PENDING}
              variant="contained"
              startIcon={<Approval />}
              fullWidth
              loadingPosition="start"
              type="button"
              disabled={approval === ApprovalState.PENDING}
              onClick={approveCallback}
            >
              Approve
            </LoadingButton>
          ) : (
            <LoadingButton
              loading={pendingBuy}
              variant="contained"
              startIcon={<LocalOffer />}
              fullWidth
              loadingPosition="start"
              type="button"
              disabled={pendingBuy}
              onClick={handleBuy}
            >
              Buy
            </LoadingButton>
          )}

          <Button
            type="button"
            variant="outlined"
            startIcon={<Cancel />}
            onClick={onDismiss}
            fullWidth
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default BuyModal;
