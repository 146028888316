import { InjectedConnector } from "@web3-react/injected-connector";
import {
  ExternalProvider,
  JsonRpcFetchFunc,
  Web3Provider,
} from "@ethersproject/providers";
import { CHAIN_ID } from "../constants";

const POLLING_INTERVAL = 12000;
const chainId = CHAIN_ID;

export const injected = new InjectedConnector({ supportedChainIds: [chainId] });

export const getLibrary = (
  provider: ExternalProvider | JsonRpcFetchFunc
): Web3Provider => {
  const library = new Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};
