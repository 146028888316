import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { Timeline } from "@mui/icons-material";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as TooltipChar,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";
import moment from "moment";
// user defined
import { Currency } from "../../../constants";
import api from "../../../utils/api";

ChartJS.register(
  Legend,
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TooltipChar
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        usePointStyle: true,
      },
      position: "top" as const,
    },
  },
  scales: {
    x: {
      type: "time" as const,
    },
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
      title: {
        display: true,
        text: "BNB",
      },
    },
    z: {
      type: "linear" as const,
      display: true,
      position: "right" as const,
      grid: {
        drawOnChartArea: false,
      },
      title: {
        display: true,
        text: "BUSD",
      },
    },
  },
};

interface PriceCardProps {
  response: any;
}

function PriceCard({ response }: PriceCardProps) {
  const { metaData, v } = response;
  const { tokenId } = metaData;

  const [graphData, setGraphData] = useState({ labels: [] } as any);

  useEffect(() => {
    async function fetchData() {
      const result = await api.get("/history", { params: { tokenId, v } });
      const historyData = result.data;
      const labels = [];

      for (let i = 0; i < historyData.length; i++) {
        labels.push(moment(historyData[i].recordTime).format("lll"));
      }

      const bnbData = historyData.filter(
        (item: any) => item.currency === Currency.BNB.address
      );
      const busdData = historyData.filter(
        (item: any) => item.currency === Currency.BUSD.address
      );

      let bnbChart = [];
      for (let i = 0; i < bnbData.length; i++) {
        bnbChart.push({
          x: moment(bnbData[i].recordTime).format("lll"),
          y: bnbData[i].price,
        });
      }
      let busdChart = [];
      for (let i = 0; i < busdData.length; i++) {
        busdChart.push({
          x: moment(busdData[i].recordTime).format("lll"),
          y: busdData[i].price,
        });
      }

      setGraphData({
        labels,
        datasets: [
          {
            label: "BNB",
            data: bnbChart,
            pointStyle: "circle",
            pointRadius: 5,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
            yAxisID: "y",
          },
          {
            label: "BUSD",
            data: busdChart,
            pointStyle: "rectRot",
            pointRadius: 8,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
            yAxisID: "z",
          },
        ],
      });
    }
    fetchData();
  }, [tokenId, v, response]);

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          <Timeline /> Price History
        </Typography>
        <Box>
          {graphData.labels.length === 0 ? (
            <Typography sx={{ textAlign: "center" }}>
              No History to show
            </Typography>
          ) : (
            <Line options={options} data={graphData} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
const MemoizedPriceCard = React.memo(PriceCard);
export default MemoizedPriceCard;
