import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
// user defined
import { AppState } from "../../state";
import api from "../../utils/api";
import { itemsPerPage } from "../../constants";

export const useNftDetail = () => {
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const v = searchParams.get("v");

  const [response, setResponse] = useState({} as any);
  const [error, setError] = useState(false);
  const [loading, setloading] = useState(true);

  const fetchData = () => {
    api
      .get(`/detail/${id}?v=${v}`)
      .then((res) => {
        setResponse(res.data);
      })
      .catch((err) => setError(true))
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    setloading(true);
    fetchData();
  }, []);

  return {
    response,
    error,
    loading,
    fetchData,
  };
};
