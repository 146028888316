import { createAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppDispatch } from "../index";

export const userLoading = createAction<void>("user/loading");
export const userLoaded = createAction<{
  name: string;
  email: string;
  walletAddress: string;
  avatarUrl: string;
  ownedNft: number[];
  favoriteNftV1: number[];
  favoriteNftV2: number[];
  isAdmin: boolean;
}>("user/loaded");
export const userFailed = createAction<void>("user/failed");
export const walletConnected = createAction<string>("user/connected");
export const walletDisconnected = createAction<void>("user/disconnected");

export const loadUser =
  (walletAddress: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(walletConnected(walletAddress));
      const res = await api.get(`/user/${walletAddress}`);
      dispatch(userLoaded(res.data));
    } catch (error) {
      dispatch(userFailed());
    }
  };
