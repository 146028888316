import React, { FC, Fragment, useCallback, useState } from "react";
import { Button, Card, CardContent, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { AppState, useAppDispatch } from "../../../state";
import {
  Delete,
  SellSharp,
  LocalOffer,
  Cancel,
  Send,
} from "@mui/icons-material";
import { Box } from "@mui/system";
import { formatEther } from "ethers/lib/utils";
import { BigNumber, constants } from "ethers";
import { TransactionResponse } from "@ethersproject/providers";
import { LoadingButton } from "@mui/lab";
// user defined
import { Currency, TokenImg } from "../../../constants";
import { GhosperNftV1, GhosperNftV2 } from "../../../constants";
import { useModal } from "../../../components/Modal";
import { useCall } from "../../../hooks/useCall";
import { useGhosperMarketplaceContract } from "../../../hooks/useContract";
import useToast from "../../../hooks/useToast";
import SellModal from "./SellModal";
import BuyModal from "./BuyModal";
import OfferModal from "./OfferModal";
import api from "../../../utils/api";
import { userLoaded } from "../../../state/user/actions";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

const ActionCard: FC<{ response: any; fetchData: () => void }> = ({
  response,
  fetchData,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: AppState) => state.auth.user);
  const { walletAddress } = user;
  const { login } = useAuth();
  const { metaData, orderData, offerData, owner, v } = response;

  const ghosperMarketContract = useGhosperMarketplaceContract();
  const { call } = useCall();
  const addToast = useToast();

  const [onPresentSellModal] = useModal(
    <SellModal nftData={response} fetchData={fetchData} />
  );
  const [onPresentBuyModal] = useModal(
    <BuyModal nftData={response} fetchData={fetchData} />
  );
  const [onPresentOfferModal] = useModal(
    <OfferModal nftData={response} fetchData={fetchData} />
  );

  const displayDeleteBtn = useCallback(() => {
    if (
      v === 1 &&
      user.ownedNft.findIndex((value) => value === metaData.tokenId) > -1
    )
      return (
        <Button
          variant="outlined"
          startIcon={<Delete />}
          onClick={handleDelete}
        >
          Delete
        </Button>
      );
    else return <></>;
  }, [response, user]);

  const displayOfferBtns = useCallback(() => {
    if (
      (orderData[0] && orderData[1] !== walletAddress) ||
      (!orderData[0] && owner !== walletAddress)
    ) {
      if (offerData[2].findIndex((item: any) => item === walletAddress) > -1)
        return (
          <Button variant="outlined" startIcon={<Cancel />}>
            Cancel Offer
          </Button>
        );
      else
        return (
          <Button
            variant="outlined"
            startIcon={<Send />}
            onClick={onPresentOfferModal}
          >
            Offer
          </Button>
        );
    } else return <></>;
  }, [response, user]);

  const [pendingCancel, setPendingCancel] = useState(false);
  const [pendingBuy, setPendingBuy] = useState(false);

  const handleDelete = () => {
    const ownedNft = user.ownedNft.filter((tokenId) => {
      return metaData.tokenId !== tokenId;
    });

    api
      .post("/user", {
        ...user,
        ownedNft,
      })
      .then((res) => {
        addToast("deleted!", "success");
        dispatch(userLoaded(res.data));
        navigate("/profile");
      });
  };
  const handleCancel = () => {
    call(ghosperMarketContract!, "cancelOrder", [
      v === 1 ? GhosperNftV1 : GhosperNftV2,
      BigNumber.from(metaData.tokenId),
    ])
      .then((response: TransactionResponse) => {
        setPendingCancel(true);
        response
          .wait()
          .then(() => {
            addToast("Succesfully cancel!", "success");
          })
          .catch(() => {
            addToast("Transaction failed!", "error");
          })
          .finally(() => {
            fetchData();
            setPendingCancel(false);
          });
      })
      .catch((error: any) => {
        console.log(error);
        if (error?.code !== 4001) {
          addToast(error.message, "error");
        }
      });
  };

  const handleBuy = () => {
    if (orderData[2] === constants.AddressZero) {
      call(
        ghosperMarketContract!,
        "buyOrder",
        [
          v === 1 ? GhosperNftV1 : GhosperNftV2,
          BigNumber.from(metaData.tokenId),
        ],
        {
          value: BigNumber.from(orderData[3]),
        }
      )
        .then((response: TransactionResponse) => {
          setPendingBuy(true);
          response
            .wait()
            .then(() => {
              addToast("You bought it!", "success");
              api.post("/history", {
                v,
                tokenId: metaData.tokenId,
                currency: orderData[2],
                price: formatEther(orderData[3]),
              });
            })
            .catch(() => {
              addToast("Transaction failed!", "error");
            })
            .finally(() => {
              setPendingBuy(false);
              if (fetchData) fetchData();
            });
        })
        .catch((error: any) => {
          console.log(error);
          if (error?.code !== 4001) {
            addToast(error.message, "error");
          }
        });
    } else {
      onPresentBuyModal();
    }
  };
  return (
    <Fragment>
      <Card>
        <CardContent>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {orderData[0] && (
                <Fragment>
                  <img
                    src={
                      orderData[2] === Currency.BNB.address
                        ? TokenImg.BNB
                        : orderData[2] === Currency.BUSD.address
                        ? TokenImg.BUSD
                        : TokenImg.GHSP
                    }
                    alt="token"
                    className="h-8 mr-3"
                  />
                  <Typography>
                    {formatEther(BigNumber.from(orderData[3]))}
                  </Typography>
                </Fragment>
              )}
            </Box>
            <Stack direction={"row"} spacing={3}>
              {walletAddress ? (
                <Fragment>
                  {displayDeleteBtn()}

                  {owner === walletAddress && (
                    <Button
                      variant="outlined"
                      startIcon={<SellSharp />}
                      onClick={onPresentSellModal}
                    >
                      Sell
                    </Button>
                  )}

                  {orderData[0] &&
                    (orderData[1] === walletAddress ? (
                      <LoadingButton
                        loading={pendingCancel}
                        variant="outlined"
                        startIcon={<Cancel />}
                        loadingPosition="start"
                        type="button"
                        disabled={pendingCancel}
                        onClick={handleCancel}
                      >
                        Cancel
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        loading={pendingBuy}
                        variant="outlined"
                        startIcon={<LocalOffer />}
                        loadingPosition="start"
                        disabled={pendingBuy}
                        onClick={handleBuy}
                      >
                        Buy Now
                      </LoadingButton>
                    ))}

                  {displayOfferBtns()}
                </Fragment>
              ) : (
                <Fragment>
                  <Button variant="outlined" onClick={login}>
                    Connect Wallet
                  </Button>
                </Fragment>
              )}
            </Stack>
          </Box>
        </CardContent>
      </Card>
    </Fragment>
  );
};

export default ActionCard;
