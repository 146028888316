import { SnackbarProvider, VariantType, useSnackbar } from "notistack";

const useToast = () => {
  const { enqueueSnackbar } = useSnackbar();

  const addToast = (msg: string, variant: VariantType) => {
    enqueueSnackbar(msg, {
      variant,
      anchorOrigin: { vertical: "top", horizontal: "right" },
    });
  };

  return addToast;
};

export default useToast;
