import React, { FC, lazy, Suspense, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";

// user defined
import ThemedSuspense from "../components/Loading";
import Header from "../components/Header";
import NftDetail from "./NftDetail";
import { AppState } from "../state";
import { ModalProvider } from "../components/Modal";

const Home = lazy(() => import("./Home"));
const Profile = lazy(() => import("./Profile"));
const Admin = lazy(() => import("./Admin"));
const Setting = lazy(() => import("./Setting"));
const Wallet = lazy(() => import("./Wallet"));
const VerifyEmail = lazy(() => import("./VerifyEmail"));
const ResetPassword = lazy(() => import("./ResetPassword"));
const NotFound = lazy(() => import("./NotFound"));

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const Views: FC = () => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const { walletConnected, user } = useSelector(
    (state: AppState) => state.auth
  );
  return (
    <ThemeProvider theme={darkTheme}>
      <ModalProvider>
        <Suspense fallback={<ThemedSuspense />}>
          <Header toggleOpen={toggleOpen} />

          <Routes>
            <Route
              path="/"
              element={<Home open={open} toggleOpen={toggleOpen} />}
            />
            <Route
              path="/profile"
              element={
                walletConnected ? (
                  <Profile open={open} toggleOpen={toggleOpen} />
                ) : (
                  <Wallet />
                )
              }
            />
            <Route
              path="/admin"
              element={walletConnected ? <Admin /> : <Wallet />}
            />
            <Route
              path="/setting"
              element={walletConnected ? <Setting /> : <Wallet />}
            />
            <Route path="/explore/:id" element={<NftDetail />} />
            <Route path="/resetpassword/:token" element={<ResetPassword />} />
            <Route path="/verify/:token" element={<VerifyEmail />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ModalProvider>
    </ThemeProvider>
  );
};
export default Views;
