import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Grid,
  Toolbar,
  Container,
  Card,
  CardActionArea,
  CardMedia,
  IconButton,
  Typography,
  CardContent,
  Divider,
  Rating,
  Stack,
} from "@mui/material";
import ReactPlayer from "react-player";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
// user defined
import { useNftDetail } from "./hooks";
import NotFound from "../NotFound";
import Loading from "../../components/Loading";
import getGhosperVideoUrl from "../../utils/getGhosperVideoUrl";
import { BSCImg } from "../../constants";
import { formatAddress } from "../../utils/format";
import ActionCard from "./components/ActionCard";
import PriceCard from "./components/PriceCard";
import OfferCard from "./components/OfferCard";
import { useSelector } from "react-redux";
import { AppState } from "../../state";
import api from "../../utils/api";

export default function NftDetail() {
  const { response, error, loading, fetchData } = useNftDetail();
  const { walletAddress } = useSelector((state: AppState) => state.auth.user);
  const { metaData, owner, v } = response;

  const ratingValue = useMemo(() => {
    if (!metaData) return 0;
    let rating = 0;
    if (metaData.attributes.length === 8 || metaData.attributes.length === 9) {
      rating = 1;
    } else if (metaData.attributes.length === 10) {
      rating = 2;
    } else if (metaData.attributes.length === 11) {
      rating = 3;
    } else {
      rating = 4;
    }

    return rating;
  }, [metaData]);

  const [favorited, setFavorited] = useState(false);
  const [favoritedUsers, setFavoritedUsers] = useState<string[]>([]);

  useEffect(() => {
    if (metaData === undefined) return;
    const favorited =
      (metaData.favorites as Array<{ walletAddress: string }>).find(
        (f) => f.walletAddress === walletAddress
      ) !== undefined;
    const favoritedUsers = (
      metaData.favorites as Array<{ walletAddress: string }>
    ).map((f) => f.walletAddress);

    setFavoritedUsers(favoritedUsers);
    setFavorited(favorited);
  }, [metaData, walletAddress]);

  const handleFavorite = useCallback(async () => {
    try {
      const { data } = await api.post("/favorite", {
        walletAddress,
        id: metaData._id,
        version: v,
      });
      const favorited =
        (data as Array<{ walletAddress: string }>).find(
          (f) => f.walletAddress === walletAddress
        ) !== undefined;
      const favoritedUsers = (data as Array<{ walletAddress: string }>).map(
        (f) => f.walletAddress
      );
      setFavoritedUsers(favoritedUsers);
      setFavorited(favorited);
    } catch (err) {
      console.error(err);
    }
  }, [metaData, v, walletAddress]);

  return error ? (
    <NotFound />
  ) : (
    <Container sx={{ minHeight: "100vh" }}>
      <Toolbar />

      {loading ? (
        <Loading />
      ) : (
        <Box m={3}>
          <Grid container spacing={5}>
            <Grid item lg={5} md={5} xs={12}>
              <Stack spacing={3}>
                <Card>
                  <Box
                    p={1}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <img src={BSCImg} alt="bsc" className="w-6" />
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        aria-label="settings"
                        onClick={handleFavorite}
                        sx={{ color: "#ff7961" }}
                      >
                        {favorited ? <Favorite /> : <FavoriteBorder />}
                      </IconButton>
                      <Typography>{favoritedUsers.length}</Typography>
                    </Box>
                  </Box>

                  <CardActionArea>
                    <CardMedia>
                      <ReactPlayer
                        url={getGhosperVideoUrl(v) + `/${metaData.tokenId}.mp4`}
                        playing={true}
                        loop={true}
                        width="100%"
                        height="100%"
                      />
                    </CardMedia>
                    <CardContent>
                      <Box mb={2}>
                        <Typography gutterBottom variant="h5" component="div">
                          {metaData.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {metaData.description}
                        </Typography>
                      </Box>
                      <Divider />

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        mt={2}
                      >
                        <Rating
                          name="read-only"
                          max={4}
                          value={ratingValue}
                          readOnly
                        />
                        <Typography>Owner: {formatAddress(owner)}</Typography>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>

                <Card>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Attributes
                    </Typography>
                    <Stack>
                      {metaData.attributes.map(
                        (
                          trait: { trait_type: string; value: string },
                          index: number
                        ) => (
                          <Box
                            key={index}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>{trait.trait_type}</Typography>
                            <Typography>{trait.value}</Typography>
                          </Box>
                        )
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>
            </Grid>
            <Grid item lg={7} md={7} xs={12}>
              <Stack spacing={3}>
                <ActionCard response={response} fetchData={fetchData} />
                <PriceCard response={response} />
                <OfferCard response={response} fetchData={fetchData} />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
}
