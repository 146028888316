import { useWeb3React } from "@web3-react/core";
import { BigNumber, constants } from "ethers";
import { useCallback, useMemo, useState } from "react";
import { TransactionResponse } from "@ethersproject/providers";
// user defined
import useNftApproved from "./useNftApproved";
import { useCall } from "./useCall";
import useToast from "./useToast";
import { useERC721Contract } from "./useContract";
import { ApprovalState } from "./useApproveCallback";

export function useApproveNftCallback(
  token?: string,
  tokenId?: BigNumber,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  const { account } = useWeb3React();
  const { call } = useCall();
  const [pending, setPending] = useState(false);
  const addToast = useToast();

  const approvedAddress = useNftApproved(token, tokenId ?? undefined);

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!tokenId || !spender) return ApprovalState.UNKNOWN;

    if (token === constants.AddressZero) return ApprovalState.APPROVED;
    // we might not have enough data to know whether or not we need to approve
    if (!approvedAddress) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    return approvedAddress !== spender
      ? pending
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [tokenId, approvedAddress, spender, pending]);

  const nftContract = useERC721Contract(token);

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      addToast("Approve was called unnecessarily", "error");
      console.error("approve was called unnecessarily");
      return;
    }
    if (!token) {
      addToast("No token", "error");

      console.error("no token");
      return;
    }

    if (!nftContract) {
      addToast("Cannot find contract of the token %tokenAddress", "error");

      console.error("tokenContract is null");
      return;
    }

    if (!tokenId) {
      addToast("Missing amount to approve", "error");

      console.error("missing amount to approve");
      return;
    }

    if (!spender) {
      addToast("No spender", "error");

      console.error("no spender");
      return;
    }

    // eslint-disable-next-line consistent-return
    return call(nftContract, "approve", [spender, tokenId])
      .then((response: TransactionResponse) => {
        setPending(true);
        response.wait().then(() => {
          addToast("Approved!", "success");
          setPending(false);
        });
      })
      .catch((error: any) => {
        console.error("Failed to approve token", error);
        if (error?.code !== 4001) {
          addToast(error.message, "error");
        }
        throw error;
      });
  }, [approvalState, token, nftContract, tokenId, spender, call, addToast]);

  return [approvalState, approve];
}
