import { useEffect, useMemo, useState } from "react";
import { useERC721Contract } from "./useContract";
import get from "lodash/get";
import { useCall } from "./useCall";
import { BigNumber } from "ethers";

const useNftApproved = (
  token?: string,
  tokenId?: BigNumber
): string | undefined => {
  const contract = useERC721Contract(token);

  const [approved, setApproved] = useState(undefined as any);

  useEffect(() => {
    if (contract && tokenId)
      contract.getApproved(tokenId).then((res) => {
        setApproved(res);
      });
  }, [contract, tokenId, approved]);

  return useMemo(
    () => (token && approved ? String(approved) : undefined),
    [token, approved]
  );
};

export default useNftApproved;
