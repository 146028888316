import React, { FC, Fragment } from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Button,
  ListItemIcon,
  Divider,
  CssBaseline,
} from "@mui/material";

import {
  Person,
  AdminPanelSettings,
  Login,
  Logout,
  Settings,
} from "@mui/icons-material";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import MenuIcon from "@mui/icons-material/Menu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { AppState } from "../../state";
import useAuth from "../../hooks/useAuth";

import { formatAddress } from "../../utils/format";
import LogoImg from "../../assets/img/Logo.png";

const pages = ["Home", "Marketplace", "Staking"];

const Header: FC<{ toggleOpen: () => void }> = ({ toggleOpen }) => {
  const { login, logout } = useAuth();
  const auth = useSelector((state: AppState) => state.auth);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Link to={"/"}>
                <img src={LogoImg} alt="logo" />
              </Link>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                sx={{ m: 2, color: "white" }}
                onClick={() => {
                  window.open(`https://ghospers.com/`);
                }}
                endIcon={<NorthEastIcon />}
              >
                Home
              </Button>

              <Link to={"/"}>
                <Button sx={{ m: 2, color: "white" }}>Marketplace</Button>
              </Link>

              <Button
                sx={{ m: 2, color: "white" }}
                onClick={() => {
                  window.open(`https://staking.ghospers.com/`);
                }}
                endIcon={<NorthEastIcon />}
              >
                Staking
              </Button>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {auth.walletConnected ? (
                <Button
                  onClick={handleOpenUserMenu}
                  sx={{
                    color: "white",
                    fontSize: "15px",
                    textTransform: "none",
                  }}
                  startIcon={
                    <Jazzicon
                      diameter={30}
                      seed={jsNumberForAddress(auth.user.walletAddress)}
                      paperStyles={{
                        width: "30px",
                        height: "30px",
                      }}
                      svgStyles={{
                        width: "30px",
                        height: "30px",
                      }}
                    />
                  }
                >
                  {auth.user.name.length === 0
                    ? formatAddress(auth.user.walletAddress)
                    : auth.user.name}
                </Button>
              ) : (
                <Button
                  onClick={handleOpenUserMenu}
                  sx={{ color: "white", fontSize: "15px" }}
                  startIcon={<AccountBalanceWalletIcon />}
                >
                  connect wallet
                </Button>
              )}

              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Link to={"/profile"}>
                  <MenuItem>
                    <ListItemIcon>
                      <Person />
                    </ListItemIcon>
                    <Typography textAlign="center">Profile</Typography>
                  </MenuItem>
                </Link>
                <Link to={"/setting"}>
                  <MenuItem>
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <Typography textAlign="center">Setting</Typography>
                  </MenuItem>
                </Link>
                {auth.user.isAdmin && (
                  <Link to={"/admin"}>
                    <MenuItem>
                      <ListItemIcon>
                        <AdminPanelSettings />
                      </ListItemIcon>
                      <Typography textAlign="center">Admin</Typography>
                    </MenuItem>
                  </Link>
                )}
                {/* <Link to={"/settings"}>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <ListItemIcon>
                      <Settings />
                    </ListItemIcon>
                    <Typography textAlign="center">Settings</Typography>
                  </MenuItem>
                </Link> */}
                <Divider />
                {auth.walletConnected ? (
                  <MenuItem onClick={logout}>
                    <ListItemIcon>
                      <Logout />
                    </ListItemIcon>
                    <Typography textAlign="center">Sign Out</Typography>
                  </MenuItem>
                ) : (
                  <MenuItem onClick={login}>
                    <ListItemIcon>
                      <Login />
                    </ListItemIcon>
                    <Typography textAlign="center">Sign In</Typography>
                  </MenuItem>
                )}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Fragment>
  );
};

export default Header;
