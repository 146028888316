import React, { useEffect, useState, FC } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from "@mui/material";
import { FormatListBulleted, LocalOffer } from "@mui/icons-material";
import { useNftDetail } from "../hooks";
import { formatAddress } from "../../../utils/format";
import { BigNumber } from "ethers";
import { Currency, TokenImg } from "../../../constants";
import { formatEther } from "ethers/lib/utils";
import moment from "moment";
import { useSelector } from "react-redux";
import { AppState } from "../../../state";
import { LoadingButton } from "@mui/lab";

import AcceptOfferModal from "./AcceptOfferModal";
import { useModal } from "../../../components/Modal";

const OfferCard: FC<{ response: any; fetchData: () => void }> = ({
  response,
  fetchData,
}) => {
  const walletAddress = useSelector(
    (state: AppState) => state.auth.user.walletAddress
  );
  const { orderData, offerData, owner } = response;

  const [offerIndex, setOfferIndex] = useState(-1);
  const [toggle, setToggle] = useState(false);
  const [onPresentAcceptOfferModal] = useModal(
    <AcceptOfferModal
      nftData={response}
      offerIndex={offerIndex}
      fetchData={fetchData}
    />
  );

  useEffect(() => {
    if (offerIndex > -1) onPresentAcceptOfferModal();
  }, [offerIndex, toggle]);

  const displayOfferPrice = (currency: string, price: BigNumber) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={
            currency === Currency.BNB.address
              ? TokenImg.BNB
              : currency === Currency.BUSD.address
              ? TokenImg.BUSD
              : TokenImg.GHSP
          }
          alt="tokenImg"
          className="h-6 mr-3"
        />
        <Typography>{formatEther(price)}</Typography>{" "}
      </Box>
    );
  };

  const displayOfferTime = (time: BigNumber) => {
    const timeNum = BigNumber.from(time).toNumber() * 1000;
    const date = new Date(timeNum);
    return moment().isAfter(moment(date).add(1, "week"))
      ? moment(date).format("lll")
      : moment(date).fromNow();
  };

  return offerData ? (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          <FormatListBulleted /> Offers
        </Typography>
        {offerData[2].length === 0 ? (
          <Typography sx={{ textAlign: "center" }}>No Offers</Typography>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell align="center">WalletAddress</TableCell>
                <TableCell align="center">Offer</TableCell>
                <TableCell align="center">Time</TableCell>
                {(owner === walletAddress ||
                  (orderData[0] && orderData[1] === walletAddress)) && (
                  <TableCell align="center">Action</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {offerData[2].map((maker: any, index: number) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{formatAddress(maker)}</TableCell>
                  <TableCell align="center">
                    {displayOfferPrice(
                      offerData[3][index],
                      offerData[4][index]
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {displayOfferTime(offerData[5][index])}
                  </TableCell>
                  {(owner === walletAddress ||
                    (orderData[0] && orderData[1] === walletAddress)) && (
                    <TableCell align="center">
                      <LoadingButton
                        variant="contained"
                        startIcon={<LocalOffer />}
                        onClick={() => {
                          setOfferIndex(index);
                          setToggle(!toggle);
                        }}
                      >
                        Accept
                      </LoadingButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  ) : (
    <></>
  );
};
export default OfferCard;
