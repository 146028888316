import React, { FC, useMemo, useState } from "react";
import {
  Button,
  Box,
  Modal,
  Stack,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
} from "@mui/material";
import { SellSharp, Cancel, Approval } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TransactionResponse } from "@ethersproject/providers";
import { InjectedModalProps } from "../../../components/Modal";
import {
  Currency,
  GhosperNftV1,
  GhosperNftV2,
  MarketPlace,
} from "../../../constants";
import { BigNumber, CallOverrides } from "ethers";
import {
  ApprovalState,
  useApproveCallback,
} from "../../../hooks/useApproveCallback";
import { useGhosperMarketplaceContract } from "../../../hooks/useContract";
import { parseEther } from "ethers/lib/utils";
import useToast from "../../../hooks/useToast";
import { useCall } from "../../../hooks/useCall";
import { useSelector } from "react-redux";
import { AppState } from "../../../state";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface OfferModalProps extends InjectedModalProps {
  nftData: any;

  fetchData: () => void;
}

const OfferModal: FC<OfferModalProps> = ({ onDismiss, nftData, fetchData }) => {
  const user = useSelector((state: AppState) => state.auth.user);
  const { metaData, v } = nftData;

  const ghosperMarketContract = useGhosperMarketplaceContract();
  const addToast = useToast();
  const { call } = useCall();

  const [offerPending, setOfferPending] = useState(false);

  const formik = useFormik({
    initialValues: {
      currency: "",
      price: "",
    },
    validationSchema: Yup.object().shape({
      currency: Yup.string().required("select currency"),
      price: Yup.number()
        .min(0, "must greater than zero")
        .required("input tokenId"),
    }),
    onSubmit: async (values) => {
      let currency: string;
      let overrides: CallOverrides | undefined;
      if (values.currency === Currency.BNB.symbol) {
        currency = Currency.BNB.address;
        overrides = {
          value: parseEther(values.price.toString()),
        };
      } else if (values.currency === Currency.BUSD.symbol)
        currency = Currency.BUSD.address;
      else currency = Currency.GHSP.address;

      call(
        ghosperMarketContract!,
        "createOffer",
        [
          v === 1 ? GhosperNftV1 : GhosperNftV2,
          BigNumber.from(metaData.tokenId),
          currency,
          parseEther(values.price.toString()),
        ],
        overrides
      )
        .then((response: TransactionResponse) => {
          setOfferPending(true);
          response
            .wait()
            .then(() => {
              addToast("Succesfully put for sale!", "success");
            })
            .catch(() => {
              addToast("Transaction failed!", "error");
            })
            .finally(() => {
              if (onDismiss) onDismiss();
              if (fetchData) fetchData();
              setOfferPending(false);
            });
        })
        .catch((error: any) => {
          console.log(error);
          if (error?.code !== 4001) {
            addToast(error.message, "error");
          }
        });
    },
  });

  const getCurrency = useMemo(() => {
    if (formik.values.currency === "") return undefined;
    else {
      if (formik.values.currency === Currency.BNB.symbol)
        return Currency.BNB.address;
      else if (formik.values.currency === Currency.BUSD.symbol)
        return Currency.BUSD.address;
      else return Currency.GHSP.address;
    }
  }, [formik.values.currency]);

  const getPrice = useMemo(() => {
    if (formik.values.price.toString() === "") return undefined;
    else return parseEther(formik.values.price.toString());
  }, [formik.values.price]);

  const [approval, approveCallback] = useApproveCallback(
    getCurrency,
    getPrice,
    MarketPlace
  );

  return (
    <Modal open={true}>
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Offer NFT
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <FormControl sx={{ mt: 2 }} fullWidth>
            <InputLabel>Currency</InputLabel>
            <Select
              value={formik.values.currency}
              label="Currency"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="currency"
            >
              <MenuItem value={Currency.BNB.symbol}>BNB</MenuItem>
              <MenuItem value={Currency.BUSD.symbol}>BUSD</MenuItem>
              {user.isAdmin && (
                <MenuItem value={Currency.GHSP.symbol}>GHSP</MenuItem>
              )}
            </Select>
            {Boolean(formik.touched.currency && formik.errors.currency) && (
              <FormHelperText>
                {formik.touched.currency && formik.errors.currency}
              </FormHelperText>
            )}
          </FormControl>

          <Box my={2}>
            <TextField
              type={"number"}
              value={formik.values.price}
              helperText={formik.touched.price && formik.errors.price}
              label="Price"
              variant="outlined"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="price"
            />
          </Box>
          <Stack spacing={2} direction="row">
            {approval !== ApprovalState.APPROVED ? (
              <LoadingButton
                loading={approval === ApprovalState.PENDING}
                variant="contained"
                startIcon={<Approval />}
                fullWidth
                loadingPosition="start"
                type="button"
                disabled={approval === ApprovalState.PENDING}
                onClick={approveCallback}
              >
                Approve
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={offerPending}
                variant="contained"
                startIcon={<SellSharp />}
                fullWidth
                loadingPosition="start"
                type="submit"
                disabled={offerPending}
              >
                offer
              </LoadingButton>
            )}

            <Button
              type="button"
              variant="outlined"
              startIcon={<Cancel />}
              onClick={onDismiss}
              fullWidth
            >
              Cancel
            </Button>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};
export default OfferModal;
