import { useCallback, useEffect } from "react";
import { UnsupportedChainIdError } from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";

import { useAppDispatch } from "../state";
// user defined
import useActiveWeb3React from "./useActiveWeb3React";
import { loadUser, walletDisconnected } from "../state/user/actions";
import { injected } from "../utils/web3React";
import { setupNetwork } from "../utils/wallet";

const useAuth = () => {
  const dispatch = useAppDispatch();
  const { chainId, activate, deactivate, setError, account, active } =
    useActiveWeb3React();

  useEffect(() => {
    if (account) {
      localStorage.setItem("Connected", "true");
      loadUser(account)(dispatch);
    } else {
      if (localStorage.getItem("Connected") === "true") {
        login();
      }
      dispatch(walletDisconnected());
    }
  }, [account, active]);

  const login = useCallback(async () => {
    const connector = injected;
    if (typeof connector !== "function" && connector) {
      activate(connector, async (error: Error) => {
        if (error instanceof UnsupportedChainIdError) {
          setError(error);
          const provider = await connector.getProvider();
          const hasSetup = await setupNetwork(provider);
          if (hasSetup) {
            activate(connector);
          }
        } else {
          if (error instanceof NoEthereumProviderError) {
            console.log("no provider was found");
          } else if (error instanceof UserRejectedRequestErrorInjected) {
            console.log(error.name, error.message);
          }
        }
      });
    } else {
      console.log("Unable to find connector");
    }
  }, [activate, setError]);

  const logout = useCallback(() => {
    deactivate();
    dispatch(walletDisconnected());
    localStorage.setItem("Connected", "false");
    // to do clear user states
  }, [deactivate, dispatch, chainId]);

  return { login, logout };
};

export default useAuth;
