import { createReducer } from "@reduxjs/toolkit";
import { resetFilter, changeFilter } from "./actions";

export interface Filter {
  status: {
    forSale: boolean;
  };
  rarity: {
    common: boolean;
    rare: boolean;
    epic: boolean;
    legendary: boolean;
  };
  price: {
    currency: string;
    min: string;
    max: string;
    sort: string;
  };
}

export const initialState: Filter = {
  status: {
    forSale: true,
  },
  rarity: {
    common: false,
    rare: false,
    epic: false,
    legendary: false,
  },
  price: {
    currency: "",
    min: "",
    max: "",
    sort: "",
  },
};

export default createReducer<Filter>(initialState, (builder) =>
  builder
    .addCase(resetFilter, (state) => initialState)
    .addCase(changeFilter, (state, { payload }) => {
      return { ...state, ...payload };
    })
);
