import { ExternalProvider } from "@ethersproject/providers";
import { CHAIN_ID } from "../constants";
import { BASE_BSC_SCAN_URLS, ChainId } from "../constants";
import getNodeUrl from "./getRpcUrl";

const NETWORK_CONFIG = {
  [ChainId.MAINNET]: {
    name: "BNB Smart Chain Mainnet",
    scanURL: BASE_BSC_SCAN_URLS[ChainId.MAINNET],
  },
  [ChainId.TESTNET]: {
    name: "BNB Smart Chain Testnet",
    scanURL: BASE_BSC_SCAN_URLS[ChainId.TESTNET],
  },
};

export const setupNetwork = async (externalProvider?: ExternalProvider) => {
  const provider = externalProvider || (window as any).ethereum;
  const chainId = CHAIN_ID as keyof typeof NETWORK_CONFIG;
  if (!NETWORK_CONFIG[chainId]) {
    console.error("Invalid chain id");
    return false;
  }
  if (provider) {
    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      });
      return true;
    } catch (switchError) {
      if ((switchError as any)?.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: NETWORK_CONFIG[chainId].name,
                nativeCurrency: {
                  name: "BNB",
                  symbol: "bnb",
                  decimals: 18,
                },
                rpcUrls: getNodeUrl(),
                blockExplorerUrls: [`${NETWORK_CONFIG[chainId].scanURL}/`],
              },
            ],
          });
          return true;
        } catch (error) {
          console.error("Failed to setup the network in Metamask:", error);
          return false;
        }
      }
      return false;
    }
  } else {
    console.error(
      "Can't setup the BSC network on metamask because window.ethereum is undefined"
    );
    return false;
  }
};
