import type { Signer } from "@ethersproject/abstract-signer";
import type { Provider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";

// ABI
import ERC20Json from "../utils/abis/ERC20.json";
import ERC721Json from "../utils/abis/ERC721.json";
import GhosperNftJson from "../utils/abis/GhosperNft.json";
import MarketplaceJson from "../utils/abis/GhosperMarketplace.json";

// Types
import { ERC20 } from "../utils/types/ERC20";
import { ERC721 } from "../utils/types/ERC721";
import { GhosperNft } from "../utils/types/GhosperNft";
import { GhosperMarketplace } from "../utils/types/GhosperMarketplace";

import { GhosperNftV1, GhosperNftV2, MarketPlace } from "../constants";

export const getContract = (
  abi: any,
  address: string,
  signer?: Signer | Provider
) => {
  const signerOrProvider = signer ?? undefined;
  return new Contract(address, abi, signerOrProvider);
};

export const getGhosperNftV1Contract = (signer?: Signer | Provider) => {
  return getContract(ERC721Json.abi, GhosperNftV1, signer) as ERC721;
};

export const getGhosperNftV2Contract = (signer?: Signer | Provider) => {
  return getContract(GhosperNftJson.abi, GhosperNftV2, signer) as GhosperNft;
};

export const getGhosperMarketplaceContract = (signer?: Signer | Provider) => {
  return getContract(
    MarketplaceJson.abi,
    MarketPlace,
    signer
  ) as GhosperMarketplace;
};

export const getERC20Contract = (
  address: string,
  signer?: Signer | Provider
) => {
  return getContract(ERC20Json.abi, address, signer) as ERC20;
};
