import { useMemo } from "react";
import { Contract } from "@ethersproject/contracts";
import { getContract, getProviderOrSigner } from "../utils";
import Erc721Json from "../utils/abis/ERC721.json";
import Erc21Json from "../utils/abis/ERC20.json";
import MarketplaceJson from "../utils/abis/GhosperMarketplace.json";
import useActiveWeb3React from "./useActiveWeb3React";
// contract
import {
  getGhosperNftV1Contract,
  getGhosperNftV2Contract,
} from "../utils/contractHelpers";
import { ERC20 } from "../utils/types/ERC20";
import { ERC721 } from "../utils/types/ERC721";
import { GhosperMarketplace } from "../utils/types/GhosperMarketplace";
import { MarketPlace } from "../constants";

export function useContract<T extends Contract = Contract>(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { account, library } = useActiveWeb3React();

  const signer = useMemo(
    () =>
      withSignerIfPossible ? getProviderOrSigner(library!, account!) : null,
    [withSignerIfPossible, library, account]
  );

  const canReturnContract = useMemo(
    () => address && ABI && (withSignerIfPossible ? library : true),
    [address, ABI, library, withSignerIfPossible]
  );

  return useMemo(() => {
    if (!canReturnContract) return null;
    try {
      return getContract(address!, ABI, signer!);
    } catch (error) {
      console.error("Failed to get contract", error);
      return null;
    }
  }, [address, ABI, signer, canReturnContract]) as T;
}

export function useERC20Contract<T extends Contract = Contract>(
  tokenAddress?: string,
  withSignerIfPossible = true
) {
  return useContract<ERC20>(tokenAddress, Erc21Json.abi, withSignerIfPossible);
}

export function useERC721Contract<T extends Contract = Contract>(
  address?: string,
  ABI: any = Erc721Json.abi,
  withSignerIfPossible = true
) {
  return useContract<ERC721>(address, ABI, withSignerIfPossible);
}

export const useGhosperNftV1Contract = () => {
  const { library } = useActiveWeb3React()!;
  return useMemo(
    () => getGhosperNftV1Contract(library!.getSigner()),
    [library]
  );
};

export const useGhosperNftV2Contract = () => {
  const { library } = useActiveWeb3React()!;
  return useMemo(
    () => getGhosperNftV2Contract(library!.getSigner()),
    [library]
  );
};

export const useGhosperMarketplaceContract = (withSignerIfPossible = true) => {
  return useContract<GhosperMarketplace>(
    MarketPlace,
    MarketplaceJson.abi,
    withSignerIfPossible
  );
};
