import { createReducer } from "@reduxjs/toolkit";
import {
  userLoading,
  userLoaded,
  userFailed,
  walletConnected,
  walletDisconnected,
} from "./actions";

export interface User {
  user: {
    name: string;
    gameUser?: {
      email: string;
      ownedSkin: number[];
      score: number;
      ghspBalance: number;
    };
    email: string;
    walletAddress: string;
    avatarUrl: string;
    ownedNft: number[];
    favoriteNftV1: number[];
    favoriteNftV2: number[];
    isAdmin: boolean;
  };
  loading: boolean;
  walletConnected: boolean;
}

export const initialState: User = {
  loading: false,
  user: {
    name: "",
    email: "",
    walletAddress: "",
    avatarUrl: "",
    ownedNft: [],
    favoriteNftV1: [],
    favoriteNftV2: [],
    isAdmin: false,
  },
  walletConnected: false,
};

export default createReducer<User>(initialState, (builder) =>
  builder
    .addCase(userLoading, (state) => {
      return {
        ...state,
        loading: true,
      };
    })
    .addCase(userLoaded, (state, { payload }) => {
      return {
        ...state,
        loading: false,
        user: payload,
      };
    })
    .addCase(userFailed, (state) => {
      return { ...state, loading: false };
    })
    .addCase(walletConnected, (state, { payload }) => {
      return {
        ...state,
        user: {
          ...state.user,
          walletAddress: payload,
        },
        walletConnected: true,
      };
    })
    .addCase(walletDisconnected, (state) => initialState)
    .addDefaultCase((state) => state)
);
